$maxMediaBreakWidth: 920px;

.uib-main-menu-button:hover {
  color: $usability-blue;
}

.uib-menubar__title {
  p {
    font-size: 20px;
  }

  &.title-hidden {
    display: none;
  }
}

.settings-button:hover,
.settings-button-active {
  color: $usability-blue-02;
}

@media all and (max-width: $maxMediaBreakWidth) {
  .uib-icon--settings {
    display: none;
  }
}

.main-menu-footer {
  padding: 0 5%;
  button {
    position: absolute;
    bottom: 1%;
    width: 90%;
    height: 48px;
    border-radius: 5px;
  }
}

.user-menu {
  position: absolute;
  top: 48px;
  right: 1px;
  border: 1px solid $draeger-blue;
  border-top: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 30;

  button {
    display: block;
    width: 100%;
    padding: 18px;
    border-bottom: 1px solid $draeger-blue;
    cursor: pointer;

    &.button-link {
      padding: 0;

      div {
        display: block;
        width: 100%;
        padding: 18px;
      }
    }
  }

  :last-child {
    button {
      border-bottom: none;
    }
  }
}

.display-button-div {
  display: block;
  width: 100%;
}
