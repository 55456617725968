.uib-card__header {
  background-color: white;
  padding-right: 12px !important;
  height: 70px;
}

.card {
  height: 100%;
  padding: 24px;
  display: flex;
  cursor: pointer;

  .card-content {
    flex-grow: 1;

    .card-button {
      height: 100%;
      width: 100%;

      .card-icon {
        font-size: 200px;
      }
    }
  }

  .card-title {
    font-size: 24px;
    background-color: $usability-blue;
    color: white;
    box-shadow: 1px 1px 1px $transparent-black-20;
    padding: 8px;
    text-align: center;
  }

  &:hover {
    .card-icon {
      color: $usability-blue;
    }
  }
}
