$maxMediaBreakWidth: 920px;

.uib-modal__title {
  font-size: 22px;
  .icon-modal-tittle {
    display: flex;
    flex-direction: row;
    align-items: center;
    .odx-icon {
      margin-right: 5px;
      font-size: 30px;
    }
  }
}

.border-box-form {
  max-height: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 0;
  padding: 7px 7px;
  border: 1px solid #c8cacc;
  overflow: auto;
}

ul.errorBoxLowerDescription {
  list-style: disc;
  margin-left: 60px;
}

.uib-modal__inner {
  max-width: 1175px;
  overflow: auto;

  &.overflow-visible {
    overflow: visible;
  }
  h3 {
    font-size: 18px;
  }

  #loading-dots {
    flex-direction: row;
  }

  .uib-modal__content {
    line-height: 1.75;
  }

  .seperate-buttons {
    button + button {
      margin-right: 10px;
    }
  }

  .uib-modal__footer .uib-button-group--right-to-left {
    .uib-button--primary,
    .danger-button {
      margin-left: 1em;
    }
  }

  .draft-modal-error {
    text-align: right;
    color: $high-vis-red-01;
  }
}

.wide-modal {
  max-width: 1180px;
  padding: 30px;
}

.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.extra-margin-space {
    width: 100% !important;
    margin-bottom: 5px;
    margin-top: 12px;
  }
  i {
    font-size: 27px;
  }
}

.flex {
  display: flex;
  flex-direction: column;
}

.form-error {
  color: var(--odx-c-danger);
  white-space: pre-wrap;
}

.form-success {
  color: var(--odx-c-highlight);
  white-space: pre-wrap;
}

.modal-form {
  .uib-form-field--required {
    .uib-form__label::after {
      content: '*';
      font-size: 18px;
      margin-left: 4px;
    }
  }

  &.single-col {
    overflow: visible;

    .uib-form__item {
      width: 50%;
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
    .uib-form__label {
      min-width: 100%;
      &.expiry-date-form-label {
        color: #c8cacc;
        margin-bottom: 5px;
      }
    }
    .uib-textfield,
    .uib-dropdown {
      width: 100%;
    }
    .tag-section {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 5fr;

      .tagfield {
        width: 100%;
        border: 1px solid #c8cacc;
        padding: 8px;
        font-size: 14px;
        display: inline-block;
        .empty-tagfield-input {
          width: 100%;
        }
        input {
          color: $gray-08;
          border: none;
        }
        input:focus {
          outline: none;
        }
        input::placeholder {
          color: #c8cacc;
        }
        .odx-chip {
          margin-right: 5px;
        }
      }
    }
    .form-error {
      margin-left: 16.666667%;
    }
    .uib-textfield__input {
      .uib-link--muted {
        pointer-events: none;
      }
    }
    &.expiry-date-form {
      margin-top: 15px;
      margin-right: 0;
      padding: 7px 7px;
      border: 1px solid #c8cacc;
    }
  }

  &.double-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 48px;
    overflow: visible;

    .language-label {
      display: flex;
      align-items: center;
      width: 33%;
      font-size: 15px;
    }

    .form-error {
      margin-left: 35%;
    }

    .form-success {
      margin-left: 35%;
    }

    .uib-textfield {
      &.feedbackIcon {
        display: flex;
        flex-direction: row;
      }
    }

    .uib-textfield__input {
      &.feedbackIcon {
        width: 85%;
        display: inline;
      }
    }

    #loading-dots {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    .uib-loading-dots__dot {
      width: 5px;
      height: 5px;
      margin: 0 2px;
    }

    i {
      display: block;
      margin-top: auto;
      margin-bottom: auto;
      font-size: 27px;
    }

    @include device-down('tablet') {
      grid-template-columns: 1fr;
    }
    .uib-form__label {
      min-width: 35%;
      max-width: 35%;
    }
  }

  .uib-form__label {
    font-weight: bold;
    padding-right: 12px;
    display: flex;
    align-items: center;
    &.increase-width {
      min-width: 36%;
    }
  }
  .uib-textfield,
  .uib-dropdown {
    width: 67%;
  }

  .multiselect {
    width: 67%;
    height: fit-content;

    .multiselect__control {
      border-radius: 0;
      background-color: #ffffff;
      min-height: 32px;

      .multiselect__value-container {
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;

        .multiselect__placeholder {
          color: #54595c;
          line-height: 1;
        }

        .multiselect__multi-value {
          background-color: $usability-blue;
          color: white;

          .multiselect__multi-value__label {
            background-color: $usability-blue;
            color: white;
          }

          .multiselect__multi-value__remove:hover {
            background-color: white;
          }
        }
      }

      .multiselect__indicators {
        cursor: pointer;

        .multiselect__indicator-separator {
          background-color: #54595c;
        }

        .multiselect__dropdown-indicator {
          color: #54595c;
          transform: scale(1.3);
        }
        .multiselect__dropdown-indicator:hover {
          color: #323332;
        }
      }

      .multiselect__option {
        color: green;
      }
    }
    .multiselect__option {
      cursor: pointer;
    }
  }

  .labeledDropdown {
    width: 100%;
    height: fit-content;

    .labeledDropdown__control {
      border: 1px solid #c8cacc;
      border-radius: 0;
      background-color: #fff;
      min-height: 32px;

      .labeledDropdown__value-container {
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;

        .labeledDropdown__placeholder {
          color: #54595c;
          line-height: 1;
        }
      }

      .labeledDropdown__indicators {
        min-width: 27px;
        cursor: pointer;
        display: flex;

        .labeledDropdown__dropdown-indicator {
          color: #54595c;
          padding: 0;
          margin-bottom: 6px;
          i {
            font-size: 22px;
            margin-top: 5px;
            margin-right: 5px;
          }
        }
        .labeledDropdown__dropdown-indicator:hover {
          color: #323332;
        }
      }

      .labeledDropdown__option {
        color: green;
      }
    }

    .labeledDropdown__option {
      cursor: pointer;
    }
  }

  .x-dock-time-input {
    grid-template-columns: 1fr 1.5fr !important;
    &.increase-width {
      .uib-form__label {
        min-width: 100%;
      }
    }
    &.tooltip-message {
      grid-template-columns: 1.75fr 1fr !important;
    }
    .uib-textfield {
      display: flex;
      align-items: center;
      .uib-textfield__input {
        width: 65px;
        margin-top: 0;
      }
    }
    span {
      margin-left: 2%;
      margin-right: 30%;
      font-size: 17px;
    }
    &.expiry-date-labeled-input {
      width: 100%;
    }
  }

  .multiple-num-pickers {
    width: 67%;
    display: flex;

    .vertically-labelled-input {
      display: flex;
      align-items: left;
      flex-direction: column;
      margin-right: 12%;

      label {
        margin-bottom: 5px;
      }

      .uib-textfield__input {
        border-color: $gray-04;
        min-width: 70px;
      }
    }
  }

  &.form-sub-section {
    min-height: 150px;
    margin-top: 18px;
    border: 1px solid $gray-07;
    padding: 12px 24px;
    background-color: $gray-01;

    .uib-form__label {
      display: inline;
    }
    .sub-label-cell {
      padding: 8px 8px;
      text-align: end;
      .sub-label {
        font-size: 15px;

        &.issued-on-label {
          color: $high-vis-green-03;
        }
        &.expired-on-label {
          color: $high-vis-red-01;
        }
      }
    }
    .uib-textfield__input {
      width: 145px;
    }
    .width-100 {
      min-width: 300px;
    }
  }
}

.center-dropdown {
  max-width: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .dropdown {
    height: fit-content;
    width: 100%;
    .dropdown__control {
      font-size: 20px;
      border-radius: 0;
      min-height: 32px;
      border: 1px solid #54595c;

      .dropdown__value-container {
        padding-top: 0;
        padding-bottom: 0;
        cursor: pointer;

        .dropdown__placeholder {
          color: #54595c;
          line-height: 1;
        }
      }

      .dropdown__indicators {
        cursor: pointer;
        display: flex;

        .dropdown__dropdown-indicator {
          color: #54595c;
        }
        .dropdown__indicator-separator {
          background-color: #54595c;
        }
        .dropdown__dropdown-indicator:hover {
          color: #323332;
        }
      }

      .dropdown__option {
        color: green;
      }
    }
    @media all and (max-width: $maxMediaBreakWidth) {
      .dropdown__control {
        font-size: 10px;
      }
    }
    .dropdown__control:hover {
      background-color: #c8cacc;
    }

    .dropdown__option {
      cursor: pointer;
    }
  }
}

.default-button-group {
  display: flex;
  div {
    flex-basis: 100%;
  }
  .buttons-main-cluster {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 1em;
  }
  .subContent {
    display: none;
  }
}

.button-group-with-subContent {
  div {
    flex-basis: 100%;
  }
  .buttons-main-cluster {
    display: flex;
    justify-content: flex-end;
  }
  button {
    margin-left: 1em;
  }
  .subContent {
    float: right;
    margin-top: 1em;
  }
}

.displayNone {
  display: none;
}

.form-legend {
  margin-top: 25px;
  span {
    font-size: 18px;
  }

  ul {
    margin-top: 10px;
    list-style: inside;
  }

  li {
    margin-bottom: 10px;
    ul {
      margin-top: 0px;
      list-style: circle;
      margin-left: 24px;
      li {
        margin-bottom: 0px;
      }
    }
  }
}

.dreagerSnCheckIcon {
  margin-left: 5%;
}
