// Ensures margin between two secondary buttons together
.uib-modal__footer .uib-button-group {
  .uib-button--secondary {
    margin-left: 1em;
  }
}

.success-button {
  background-color: $high-vis-green-03;
  color: $white;
}

.danger-button {
  background-color: $high-vis-red-01;
  color: $white;
}

.warning-button {
  background-color: $high-vis-yellow-01;
}

button {
  &:disabled {
    pointer-events: none;
  }
}

.tooltip-button {
  margin-left: 0px !important;
  &.uib-iconbutton {
    height: auto;
    width: 34px;
  }
}

.square-bubble {
  position: absolute;
  height: 30px;
  width: auto;
  background: #c7d3e0;
  z-index: 100;
  transform: translateX(-85%);
  margin-top: -35px;
  p {
    color: #002766;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3px;
    margin-bottom: 3px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.square-bubble:after {
  border: 7px solid #c7d3e0;
  content: '';
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  position: relative;
  bottom: -5px;
  left: calc(50% - 7px);
  z-index: 1;
}

.min-w-100 {
  min-width: 100px !important;
}
