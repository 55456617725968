.uib-main {
  background-color: #eaeef2;
}

.uib-card {
  height: auto;
}

.center-content-with-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content-with-flex-space-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

// ODX
.odx-blue-text {
  color: var(--odx-c-highlight);
}

body, html {  
  background-color: #eaeef2;
  width: 100%;
}  

.outer-div {  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  height: 100%;
}

.inner-div {  
  width: 100%; 
  padding-left: 10px; 
  padding-right: 10px;
}
