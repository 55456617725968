$maxMediaBreakWidth: 920px;

.uib-table {
  background-color: white;
}

.vertical-table,
.horizontal-table,
.drag-and-drop-table {
  &.uib-table {
    table-layout: auto;
  }

  .uib-search {
    height: 32.84px;
    margin-bottom: 3px;
    padding: 0;
    width: auto;

    .uib-search__input {
      border-bottom: 1px solid $gray-08;
    }
    .uib-search__input:focus {
      border-bottom-color: $usability-blue;
    }

    &.uib-search:focus-within .uib-search__input {
      border-bottom-color: $usability-blue;
      color: $usability-blue;

      .uib-search__icon {
        color: $usability-blue;
      }
    }
  }

  .uib-table__row td {
    width: auto;

    .align-multi-items {
      display: flex;
      align-items: center;
      justify-content: left;

      i {
        margin-left: 10px;
      }
    }
  }

  thead {
    .header-text {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      white-space: normal;
      overflow-wrap: break-word;
      font-size: 18px;
      height: 39px;

      .checkboxHeaderContainer {
        margin-left: auto;
        margin-right: auto;
        margin-top: 22px;
        z-index: 20;
      }
    }

    .reduce-width {
      width: 100px;
    }
  }

  tbody {
    .uib-table__row {
      border-bottom: 1px solid var(--gray-100);

      td {
        cursor: pointer;
        width: auto;
        padding: 10px 10px 10px 10px;

        &.table-image-cell {
          height: 65px;
          img {
            max-height: 65px;
            max-width: 100%;
            transform: scale(0.8);
          }

          border-bottom: 1px solid $gray-03;
          text-align: center;
          background-color: $image_background_color;
        }

        &.open-locker-cell {
          height: 2em;
          text-align: center;
          img {
            // TODO: add max-width when the width of the columns has been decided. At the moment they are just equal (- 22/7/2021)
            max-height: 2em;
            transform: scale(0.8);
          }
        }
        .action-button-group {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .action-button-container {
            max-width: 100%;
            min-width: 100%;
            display: flex;
            justify-content: center;
          }

          &.with-3-buttons {
            grid-template-columns: repeat(3, 1fr);
          }

          &.with-4-buttons {
            grid-template-columns: repeat(4, 1fr);
          }

          button {
            width: 58px;
            height: 48px;
          }
        }
        @media all and (max-width: $maxMediaBreakWidth) {
          .action-button-group {
            display: flex;
            flex-direction: column;
            max-width: 50px;
          }
        }
      }
      @media all and (max-width: $maxMediaBreakWidth) {
        td {
          padding: 0 7px;
        }
      }
    }

    .uib-table__row:hover {
      background-color: var(--gray-50);

      .table-image-cell {
        background-color: $usability-blue-04;
      }
    }
  }

  .uib-table__row--checked {
    background: var(--odx-c-selected) !important;
  }

  .uib-table--plain .uib-table__row:nth-child(even) {
    &.uib-table__row--selected {
      background: $usability-blue;
    }
  }

  .uib-table__row--selected .uib-icon {
    color: white;
  }
}

.horizontal-table {
  tr {
    border-bottom: 1px solid #d8dce0;
  }
  th {
    padding: 0 12px;
    width: 25%;
    .header-text {
      padding: 10px 0;
    }
  }
  td {
    border-left: 1px solid #d8dce0;
    padding: 0 12px;
    width: 25%;
  }
}

.drag-and-drop-table {
  table {
    table-layout: auto;
    thead {
      .drag-column-header {
        width: 60px;
      }
    }
    tbody {
      .uib-table__row {
        td {
          &.drag-cell {
            position: relative;
            .drag-icon-container {
              font-size: 32px;
              text-align: center;

              i {
                color: $usability-blue-03;
                cursor: grab;

                &.is-filtering {
                  color: $gray-06;
                  cursor: not-allowed;
                }
              }
            }
            .drag-cover {
              position: absolute;
              height: 48px;
              width: inherit;
              top: 0;
              left: 0;
              z-index: 20;
              background-color: transparent;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.pagination {
  margin-top: 5px;
  display: flex;
  width: 100%;

  .pagination-buttons {
    width: 100px;
    display: flex;
  }

  .pagination-label {
    line-height: 48px;
    height: 48px;
    text-align: center;
  }

  button,
  div {
    flex: 1;
  }

  button {
    i {
      &.narrow {
        width: 8px;
      }
    }
  }

  .uib-dropdown {
    margin-top: 7px;
    height: 32px;
  }
}
@media all and (max-width: $maxMediaBreakWidth) {
  html {
    overflow: hidden;
    width: 100%;
  }

  body {
    height: 100%;
    position: fixed;
    overflow-y: scroll;
  }
}

.pair-with-terminal-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media all and (max-width: 1160px) {
  .vertical-table,
  .horizontal-table {
    thead {
      .reduce-width {
        width: auto;
      }
    }
  }
}

@media all and (max-width: 1210px) {
  .vertical-table-scrollable-x {
    table-layout: auto !important;
    overflow-x: scroll;
  }
}

.multiselect-filter {
  width: 100%;
  max-width: 285px;
  height: fit-content;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: -6px;

  .multiselect-filter__control {
    width: 100%;
    border: 1px solid #54595c;
    border-radius: 0;
    background-color: #fff;
    min-height: 32px;
    .multiselect-filter__value-container {
      .multiselect-filter__placeholder {
        color: #54595c;
        line-height: 1;
      }
      .multiselect-filter__multi-value {
        background-color: $usability-blue;
        color: white;
        .multiselect-filter__multi-value__remove {
          display: none !important;
        }
      }
    }
    .multiselect-filter__indicators {
      cursor: pointer;
      .multiselect-filter__indicator-separator {
        background-color: #54595c;
      }
      .multiselect-filter__dropdown-indicator {
        color: #54595c;
        transform: scale(1.3);
      }
      .multiselect-filter__dropdown-indicator:hover {
        color: #323332;
      }
    }
  }
  .multiselect-filter__option {
    cursor: pointer;
  }
}
.svg-container {
  padding-top: 5px;
}

@media all and (max-width: $maxMediaBreakWidth) {
  .uib-board {
    padding: 0;
  }
}

.checkboxContainer {
  display: flex;
  justify-content: center;
}

.checkbox {
  width: 20px;
  height: 20px;
}

.no-stock-container {
  height: calc(100vh - 144px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.2em;
}
.no-stock-container span,
.no-stock-container li {
  padding-bottom: 9px;
}
.no-stock-container ol {
  list-style: revert;
}

.uib-icon--table {
  font-size: 120px;
}

.no-stock-bold-text {
  font-weight: bold;
}

.uib-table--plain .uib-table__row th,
.uib-table--plain .uib-table__row td {
  border: none;
  padding: 0 6px;
}

.uib-table--plain .uib-table__row--header {
  border-bottom: 2px solid gray;
  background-color: #fff;
}
.uib-table--plain .uib-table__row--header:hover {
  background-color: #fff;
}

.uib-search {
  width: auto;
}
