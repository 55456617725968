.tag {
  background-color: $usability-blue;
  color: white;
  margin: 2px;
  border-radius: 2px;
  display: inline-flex;

  .tag-label {
    font-size: 85%;
    overflow: hidden;
    padding: 3px;
    padding-left: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tag-remover {
    color: white;
    padding: 4px;
    border-radius: 2px;
    cursor: pointer;
    i {
      transform: scale(0.9);
    }
  }
  .tag-remover:hover {
    background-color: white;
    color: #de350b;
  }
}
