// Design Library imports
// https://usability-is-blue-release.azurewebsites.net/?path=/story/welcome--intro
@import '~@uib/css/lib/public/css/draeger-theme-bright.css';
@import '~@uib/css/lib/src/css/base/variables/color';
@import '~@uib/icons/public/css/uib-iconfont.css';

// Partials imports
@import './partials/variables';
@import './partials/media-queries';

// General SCSS
@import './general.scss';

// Component SCSS
@import './components/Button.scss';
@import './components/card.scss';
@import './components/DetailView.scss';
@import './components/LabeledTagfield.scss';
@import './components/Modal.scss';
@import './components/Switch.scss';
@import './components/table.scss';

// Layout SCSS
@import './layout/BurgerMenu.scss';
@import './layout/header.scss';
@import './layout/TablePageLayout.scss';

// ODX Icons
@import '~@odx/icons/core/core-icons.css';
@import '~@odx/icons/medical/medical-icons.css';
@import '~@odx/icons/safety/safety-icons.css';

// ODX UI
@import '@odx/icons/core/core-icons.css';
@import '@odx/ui/core-theme.css';

html {
  font-size: 16px;
}

:root {
  --odx-typography-base-size: 16px;
}

// Kill UIB

html, body {
 overflow: auto !important;
}

.uib-board__box {
  padding: 0px;
}

.draeger_header {
  margin-bottom: 15px;
}
