$maxMediaBreakWidth: 920px;

.detail-view-modal {
  h3 {
    font-weight: bold;
  }
  .detail-view-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    .header-additional-info {
      display: flex;
      flex-direction: column;

      .x-dock-label,
      .x-dock-alert-label {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h3 {
          padding: 0 6px;
        }

        i {
          font-size: 48px;
        }
      }
    }
  }

  .detail-view-content {
    position: relative;
    .table-container {
      max-width: 75%;
      max-height: 100%;
      @for $i from 1 through 4 {
        &.col-count-#{$i} {
          max-width: 18.75% * $i;
        }
      }
    }
    .first-layer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 150px;

      &.is-item-detail {
        height: 200px;
        align-items: flex-start;
        margin-top: 12px;
      }
    }
    .third-layer {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      i {
        font-size: 35px;
      }
      .pagination {
        i {
          font-size: 20px;
        }
      }
    }
  }

  table {
    font-size: 16px;
    thead {
      .header-text {
        padding: 10px 0;
        height: auto;
      }
    }
  }
}
.info-card {
  width: 23%;
  border: 2px solid $gray-03;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: -57px;
  right: 0;

  .card-content {
    max-width: 100%;
    max-height: 100%;
    margin: 8px;

    h4 {
      font-weight: bold;
      font-size: 18px;
      max-width: 200px;
      margin: auto;
    }

    .card-infos {
      text-align: left;
      margin-left: 8px;
      margin-right: 8px;
      &.additional-margin-top {
        margin-top: 10px;
      }
      .label {
        font-weight: bold;
      }
    }

    .img-container {
      max-height: 125px;
      max-width: 125px;
      margin: 0 auto;
      img {
        max-height: 120px;
        max-width: 120px;
      }
    }
    .x-dock-label {
      display: flex;
      align-items: center;
      justify-content: left;

      h3 {
        font-size: 16px;
        padding: 8px 6px;
      }

      i {
        font-size: 28px;
      }
    }
  }
}

.icon-size {
  font-size: 28px;
}

@media all and (max-width: $maxMediaBreakWidth) {
  .info-card {
    position: static;
  }
}
