.home-button {
  font-size: 14px;
  padding-left: 24px;
  padding-right: 12px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  user-select: none;
  transition: 0.2s ease;
  width: 100%;
  font-weight: bold;

  i {
    color: $usability-blue;
    font-size: 50px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.burger-list-scrollable {
  height: calc(100vh - 180px);
  overflow-y: scroll;
}
