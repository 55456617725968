.switch-label {
  padding-top: 5px;
  margin-right: 10px;
  font-weight: bold;
}
.switch-container {
  display: flex;
  align-items: center;
  .switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}

.switch-ui-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-selected {
  background: var(--odx-c-highlight);
}

.switch-disabled {
  background: $gray-05;
}

.switch-ui-label .switch-button {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22.5px;
  height: 22.5px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-ui-label .switch-button {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

.switch-ui-label:active .switch-button {
  width: 60px;
  &.switch-button-disabled {
    width: 22.5px;
  }
}
