$maxMediaBreakWidth: 920px;

.uib-board__box__full-width {
  flex: 0 0 100%;
  height: auto;
}

.uib-board__box {
  min-width: 0;
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 300;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .image {
    height: 50%;
  }
}

.image-input {
  display: flex;
  align-items: center;
  justify-content: center;
  &.direction-column {
    flex-direction: column;
  }
  .image-input-button {
    max-width: 200px;
    min-width: calc(var(--odx-vertical-rythm-base-size) * 3);
  }
  .edit-modal-image {
    max-height: 15vh;
  }
  .edit-modal-image-name {
    width: 175px;
    margin-left: 10px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-self: center;
  }
}

.cell-icon-container {
  font-size: 32px;
  display: flex;
  align-content: center;
  justify-content: space-around;
}

.sub-table-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: -24px;

  .sub-table-button {
    margin: 16px 0;
    box-shadow: 5px 5px 5px grey;
    height: 48px;
    margin-right: 16px;

    span {
      transform: scale(2.75);
    }
  }
}

.uib-card__header {
  justify-content: space-between;
  padding: 0 12px;
}

.header-button-container {
  .header-button {
    height: 35px;
    width: 35px;
    min-width: 40px;
    padding: 0;
    margin: 5px;
  }

  button + button {
    margin-left: 10px;
  }
}

@media all and (max-width: $maxMediaBreakWidth) {
  .sub-table-buttons-container {
    display: none;
  }
}
